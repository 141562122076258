import {
  formatDate,
  formatFullDate,
} from "@/shared/helpers/numbersManipulation";
import i18n from "@/i18n/wrappers/i18n";
import moment from "moment";

const t = i18n.global.t;

/**
 * generate formData from payload dinamically
 * @param {*} payload
 */
export const generateformData = (payload) => {
  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    if (payload[key] instanceof Array)
      payload[key].forEach((i) => formData.append(key, i));
    else formData.append(key, payload[key]);
  });

  return formData;
};

/**
 * get mission item label
 * @param {*} rewards
 * @param {*} credits
 */
export const getLabel = (rewards, credits) =>
  rewards > 0 && credits > 0
    ? t("missions.credit_if_complete_with_reward", { value: credits })
    : rewards === 0 && credits > 0
    ? t("missions.credit_if_complete_show", { value: credits })
    : rewards > 0 && credits === 0
    ? t("missions.credit_if_complete_only_reward")
    : t("missions.only_reward");

/**
 * @param {*} mission
 */
export const getEventPeriod = (mission) =>
  mission.time_in_days
    ? t("missions.time_in_days", { value: mission.time_in_days })
    : t("missions.period", {
        d1: formatDate(mission.start_at),
        d2: formatDate(mission.end_at),
      });

/**
 * @param {*} mission
 */
export const getEventPeriodSubs = (mission) =>
  t("missions.period", {
    d1: formatDate(mission.subscribe_start_at),
    d2: formatDate(mission.subscribe_end_at),
  });

/**
 * @param {*} status
 */
export const getBadgePaticipantColor = (status) => {
  const badgeColors = {
    Content_approved: "green-7",
    Content_received: "blue-7",
    Content_reproved: "red-9",
    Content_to_be_improved: "orange-9",
  };
  return badgeColors[status];
};

/*
export const getTargetIcon = (target) => {
  return target == t("missions.targets.flip")
    ? "fas fa-upload"
    : target == t("missions.targets.social")
    ? `fab fa-google-${target.toLowerCase()}`
    : `fab fa-${target.toLowerCase()}`;
};
*/

export const getTargetIcon = (target) => {
  const targetIcons = {
    ["Texto"]: "text_fields",
    [t("missions.targets.flip")]: "fas fa-upload",
    [t("missions.targets.social")]: `fab fa-google-${target.toLowerCase()}`,
  };
  return targetIcons[target] || `fab fa-${target.toLowerCase()}`;
};

export const accountData = (acc) => [
  acc.toLowerCase() == "pj" ? "pj_fantasia" : "nome",
  "email_principal",
  "celular",
  "end_cep",
  "end_rua",
  "end_numero",
  "end_bairro",
  "end_cidade",
  "end_uf",
];

export const getOptionLabels = (uploadType, targets) => {
  return uploadType && targets.length > 1
    ? 1
    : !uploadType && targets.length >= 1
    ? 2
    : 3;
};

export const getMoment = (date) => moment(date, "DD/MM/YYYY"),
  getDiffDays = (date, today) => getMoment(date).diff(getMoment(today), "days"),
  getBadgeMessage = (diffDays) =>
    diffDays < 0
      ? t("missions.expired_period")
      : diffDays > 0
      ? t("missions.end_in", { value: diffDays })
      : t("missions.end_today");

export const showDeadlineBadge = (start_current_step, currentStep) => {
  const today = moment().format("DD/MM/YYYY");

  if (currentStep.when_date) {
    const deadline = moment(currentStep.when_date).format("DD/MM/YYYY"),
      diffDays = getDiffDays(deadline, today);

    return getBadgeMessage(diffDays);
  }
  if (currentStep.when_integer) {
    const deadline = moment(start_current_step).add(
        currentStep.when_integer,
        "days"
      ),
      diffDays = getDiffDays(deadline, today);

    return getBadgeMessage(diffDays);
  }
};

export const getFinishDate = (date, integer, start_current_step) => {
  if (date) return t("missions.finish_date", { value: formatFullDate(date) });
  if (integer) {
    const deadline = moment(start_current_step).add(integer, "days");
    return t("missions.finish_date", {
      value: moment(deadline).format("DD/MM/YYYY"),
    });
  }
};
