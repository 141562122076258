<template>
  <q-card flat class="q-ma-sm">
    <q-img
      v-if="reward.images.length == 0"
      :ratio="16 / 9"
      style="display: block; margin: auto"
      :src="$t('image_links.app_img_default')"
    />

    <q-carousel
      v-else
      animated
      infinite
      v-model="slide"
      :ratio="16 / 9"
      :autoplay="2000"
      style="display: block; margin: auto; height: 150px;"
    >
      <q-carousel-slide
        :key="i"
        :name="i"
        :img-src="r.url"
        v-for="(r, i) in JSON.parse(reward.images)"
      />
    </q-carousel>

    <q-card-section>
      <div
        class="text-subtitle2 text-uppercase"
        style="font-size: 9pt;"
        v-html="reward.description"
      />
    </q-card-section>
  </q-card>
</template>

<script>
import { ref } from "vue";

export default {
  name: "RewardCardComponent",

  props: {
    reward: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return { slide: ref(0) };
  },
};
</script>
