<template>
  <div class="course-desc-content" v-if="mission">
    <div
      class="row wrap-reverse default-card-border default-rounded bg-white q-pa-lg"
    >
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <h5
          class="text-weight-bold text-blue-grey-9 q-mb-md text-uppercase"
          v-html="mission.title"
        />
        <p
          style="font-size: 12pt;"
          class="text-blue-grey-9 q-mb-md "
          v-html="mission.short_description"
          :class="$q.platform.is.desktop ? 'q-mr-md' : 'text-justify'"
        />
        <div class="q-gutter-md q-mt-md">
          <q-icon
            :key="i"
            size="30px"
            width="30px"
            height="30px"
            :name="network.icon"
            v-for="(network, i) in filterNetworks(networks)"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 self-center">
        <q-img
          class="default-rounded"
          :class="$q.platform.is.desktop ? 'q-mr-sm q-ml-sm' : ''"
          :src="getImage(mission.asset)"
          :ratio="16 / 9"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const useAssents = createNamespacedHelpers("assents");

export default {
  name: "ShowMissionHeaderComponent",

  props: {
    mission: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n(),
      networks = ref([]),
      { ImgNotFound } = useAssents.useGetters(["ImgNotFound"]),
      getImage = (asset) => (asset ? asset.url : ImgNotFound);

    const filterNetworks = (networks) => {
      const networksFiltered = networks
        .map((network) => {
          // Remover conteúdo entre parênteses
          const iconName = network.icon.replace(/\([^)]+\)/, "").trim();
          return { icon: iconName };
        })
        .filter((network, index, self) => {
          // Filtrar itens únicos
          return index === self.findIndex((t) => t.icon === network.icon);
        });

      return networksFiltered;
    };

    const targetIcons = {
      [t("missions.targets.flip")]: "",
      [t("missions.targets.social")]: "fab fa-google-social",
      [t("missions.targets.global")]: "fas fa-globe",
      ["drive"]: "fab fa-google-drive",
    };

    networks.value = props.mission.mission_include
      .filter((i) => i.target_name !== t("missions.targets.flip"))
      .map((i) => {
        const icon =
          targetIcons[i.target_name.toLowerCase()] ||
          `fab fa-${i.target_name.toLowerCase()}`;
        return { icon };
      });

    return {
      networks,
      getImage,
      filterNetworks,
    };
  },
};
</script>
