<template>
  <div class="col-12 col-md-8" v-if="Object.keys(mission).length">
    <div
      class="default-card-border full-height default-rounded bg-white q-pa-lg"
      :class="$q.screen.width > 1025 ? 'q-mr-md' : 'q-mt-md'"
    >
      <div class="text-h6 text-uppercase" v-html="$t('missions.briefing')" />
      <div
        class="text-justify q-mt-md"
        v-html="mission.briefing"
        v-if="mission.briefing && mission.briefing.length"
      />
      <div
        v-else
        class="text-center q-mt-sm text-weight-light text-h6 bg-grey-3 q-pa-xl default-rounded"
        v-html="$t('missions.hasnt_briefing')"
      />

      <div v-if="mission.rewards.length" class="q-mt-lg">
        <div class="text-h6 text-uppercase" v-html="$t('missions.reward')" />
        <div class="q-pa-sm q-mt-sm bg-grey-3 default-rounded">
          <div class="row">
            <div
              class="col-12"
              :class="$q.screen.width > 1368 ? 'col-md-3' : 'col-md-4'"
              v-for="(reward, i) in mission.rewards"
              :key="i"
            >
              <reward-card-component :reward="reward" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4" v-if="Object.keys(mission).length">
    <div
      class="default-card-border full-height default-rounded bg-white q-pa-lg"
    >
      <template v-if="$route.name !== 'view-finished'">
        <q-btn
          unelevated
          color="dark"
          @click="isPublicRoute ? wantEnrollFn() : enrollFn()"
          :label="
            $t(getButtonLabel(mission, isPublicRoute ? 'public' : 'private'))
          "
          :disable="
            verifyDisable(mission, isPublicRoute ? 'public' : 'private')
          "
          :class="{
            'q-mb-lg': !wrappedLayout,
          }"
          class="default-rounded text-weight-bolder full-width q-pa-md"
          style="font-size: 14pt;"
        />

        <div
          class="text-red-8 text-center"
          :class="{
            'q-mt-md': wrappedLayout,
          }"
        >
          <span style="font-size: 12pt;">
            <q-icon name="monetization_on" class="label-color" />
            <span
              class="label-color"
              v-html="
                getLabel(mission.rewards.length, mission.credits_if_complete)
              "
            />
          </span>
        </div>
      </template>

      <!--
      <div class="q-mt-md">
        <div
          class="text-h6 text-uppercase"
          v-html="$t('missions.participants')"
        />
        <div v-if="mission.limit">
          <div class="q-mt-sm">
            <q-icon name="groups" class="icon" />
            <span
              v-html="
                $t('missions.control_participants', {
                  value1: mission.total_participants,
                  value2: mission.limit,
                })
              "
            />
          </div>
        </div>
        <div v-else>
          <div class="q-mt-sm">
            <span>
              <q-icon name="groups" class="icon" />
              <span v-html="$t('missions.enroll_no_limit')" />
            </span>
          </div>
        </div>
      </div>
    -->

      <template v-for="(p, i) in periods" :key="i">
        <div :class="'q-mt-lg'" v-if="p.verify">
          <div class="text-h6 text-uppercase" v-html="$t(p.label)" />
          <div class="q-mt-sm">
            <q-icon name="event" style="font-size: 14pt;" />
            <span v-html="p.value" class="q-ml-sm" />
          </div>
        </div>
      </template>

      <div class="q-mt-lg">
        <div class="text-h6 text-uppercase" v-html="$t('missions.level')" />
        <div v-if="mission.level && Object.keys(mission.level).length">
          <div class="q-mt-sm">
            <q-icon name="insights" class="icon" />
            <span style="cursor:pointer"
              ><b>{{ $t(mission.level.level) }}</b>
              <q-tooltip>
                {{ $t(mission.level.details) }}
              </q-tooltip>
            </span>
          </div>
        </div>
        <div v-else>
          <div class="q-mt-sm">
            <span>
              <q-icon name="info" class="icon" />
              <span v-html="$t('missions.hasnt_level')" />
            </span>
          </div>
        </div>
      </div>

      <div class="q-mt-lg">
        <div
          class="text-h6 text-uppercase"
          v-html="$t('missions.requirements')"
        />
        <div v-if="mission.requirements.length">
          <div
            class="q-mt-sm"
            v-for="(req, i) in mission.requirements"
            :key="i"
          >
            <q-icon name="done" class="icon" />
            <span
              v-html="
                req.value && req.helper
                  ? $t('missions.requirement', {
                      value: getRequirement(req.helper, req.value),
                    })
                  : req.description
              "
            />
          </div>
        </div>
        <div v-else>
          <div class="q-mt-sm">
            <span>
              <q-icon name="info" class="icon" />
              <span v-html="$t('missions.hasnt_requirements')" />
            </span>
          </div>
        </div>
      </div>
      <div class="q-mt-lg">
        <div class="text-h6 text-uppercase" v-html="$t('missions.behaviors')" />
        <div v-if="mission.behaviors.length">
          <div class="q-mt-sm" v-for="(b, i) in mission.behaviors" :key="i">
            <span>
              <q-icon name="psychology" class="icon" />
              <span v-html="b.requirement" />
            </span>
          </div>
        </div>
        <div v-else>
          <div class="q-mt-sm">
            <span>
              <q-icon name="info" class="icon" />
              <span v-html="$t('missions.hasnt_behaviors')" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLabel,
  getEventPeriod,
  getEventPeriodSubs,
} from "../helpers/script_helpers";
import RewardCardComponent from "./internal-components/RewardCardComponent.vue";
import helpers from "@/shared/helpers";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";

const formatTimestamp = helpers.numbersManipulation.formatTimestamp;

export default {
  name: "ShowMissionBodyComponent",

  components: {
    RewardCardComponent,
  },

  props: {
    mission: {
      type: Object,
      required: true,
    },
  },

  emits: ["enroll", "wantEnroll"],

  setup(props, { emit }) {
    const route = useRoute(),
      q = useQuasar();

    const subStartDate = ref(null),
      subEndDate = ref(null),
      nowDate = ref(null);

    const getRequirement = (helper, value) => helper.replace("{value}", value),
      enrollFn = () => emit("enroll"),
      wantEnrollFn = () => emit("wantEnroll");

    const periods = ref([
      {
        verify: props.mission.subscribe_start_at,
        label: "missions.period_subs_label",
        value: getEventPeriodSubs(props.mission),
      },
      {
        verify: props.mission.start_at,
        label: "missions.period_label",
        value: getEventPeriod(props.mission),
      },
    ]);

    const verifyDisable = (
      { /*limit,*/ participants, /* total_participants,*/ subscribe_vigente },
      type
    ) =>
      (type === "private" && participants.length > 0) ||
      !subscribe_vigente; /*||
      (limit && total_participants >= limit);*/

    const getButtonLabel = (
      { /*limit*/ participants, /* total_participants*/ subscribe_vigente },
      type
    ) => {
      if (type === "private" && participants.length) {
        return participants[0].status.status === "cancelled"
          ? "missions.is_unsubscribed"
          : "missions.cant_enroll";
      }

      if (!subscribe_vigente) {
        return nowDate.value > subEndDate.value
          ? "missions.enroll_ended"
          : nowDate.value < subStartDate.value
          ? "missions.enroll_not_started"
          : "missions.enroll";
      }

      return type === "private" ? "missions.enroll" : "missions.wanna_enroll";
      /* return limit && total_participants >= limit
        ? "missions.enroll_limit"
        :  type === "private"
        ? "missions.enroll"
        : "missions.wanna_enroll";
        */
    };

    onMounted(() => {
      subStartDate.value = formatTimestamp(props.mission.subscribe_start_at);
      subEndDate.value = formatTimestamp(props.mission.subscribe_end_at);
      nowDate.value = formatTimestamp(new Date());
    });

    return {
      periods,
      nowDate,
      subEndDate,
      subStartDate,
      enrollFn,
      getLabel,
      wantEnrollFn,
      verifyDisable,
      getRequirement,
      getButtonLabel,
      isPublicRoute: route.name == "public.missions-show",
      wrappedLayout: ref(q.platform.is.mobile || q.screen.width < 800),
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/styles/app.scss";
.icon {
  font-size: 14pt;
  margin-right: 5px;
}
.label-color {
  color: $pinkColorSite;
}
</style>
